@import './base.css.scss';

$max-width: 1366px;
$max-content-width: 980px;
$min-width: 260px;
$padding-sides: 7em;
$p-font-size: 1rem;
$medium-header-font-size: 1.5rem;
$dark-red: #a52238;
$light-gray: #f5f5f3;
$off-white: #f5f5f3;
$white: #ffffff;
$dark-gray: #594545;
$black: HSLA(30, 20%, 2%, 1);

@mixin for-phone-only {
  @media (max-width: 599px) {
    @content;
  }
}

@mixin for-tablet-portrait-up {
  @media (min-width: 600px) {
    @content;
  }
}

@mixin for-tablet-landscape-up {
  @media (min-width: 900px) {
    @content;
  }
}
@mixin for-desktop-up {
  @media (min-width: 1200px) {
    @content;
  }
}
@mixin for-big-desktop-up {
  @media (min-width: 1800px) {
    @content;
  }
}

.frontpage {
  position: relative;
  min-height: 100vh;
}
.frontpage-container {
  position: relative;
  min-height: 100vh;
  background-color: $light-gray;
}
#body-wrapper {
  background-color: #fff;
  // background-image: -webkit-linear-gradient(left, white, $secondary-color, white );
  // background-image: linear-gradient(left, white, $secondary-color, white);
  overflow-x: hidden;
  position: relative;
  min-height: 100vh;
}
#frontpage {
  max-width: $max-width;
  margin: auto;
}
.button {
  background-color: $primary-color;
  background-image: -webkit-linear-gradient($primary-color, #c03);
  background-image: linear-gradient($primary-color, #c03);
  border: 1px solid $primary-color;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  border-radius: 0;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  padding: 6px 30px;
  background-color: $primary-color;
  width: initial;
  max-height: 40px;
  height: 40px;
  font-size: 16px;
  &:hover {
    background-image: -webkit-linear-gradient(#f5003d, #c03);
    background-image: linear-gradient(#f5003d, #c03);
  }
}
.button.button-secondary {
  background-image: linear(hsla(0, 0%, 94%, 1) 0%, #d2cccc 100%);
  background-image: -webkit-linear-gradient(
    hsla(0, 0%, 94%, 1) 0%,
    #d2cccc 100%
  );
  background-image: -ms-linear-gradient(top, #f7f7f7 0%, #d2cccc 100%);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  color: #4c4c4c;
  border: 1px solid #d2cccc;
  &:hover {
    background-image: linear(hsla(0, 0%, 97%, 1) 0%, #d2cccc 100%);
    background-image: -webkit-linear-gradient(
      hsla(0, 0%, 97%, 1) 0%,
      #d2cccc 100%
    );
    color: #4c4c4c;
  }
}
.border-bottom {
  border-bottom: 2px solid $primary-color;
}
.frontpage-header-container {
  background: #f6f6f5;
  border-bottom: 1px solid #bbb;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  height: 100px;
  box-shadow: $box-shadow-color;
  z-index: 1;
}
@media (max-width: 840px) {
  #page-head div div div h1 a {
    width: 250px;
  }
  nav ul li {
    .button.button-primary {
      width: 100px;
      padding: 6px 20px;
    }
    .button.button-secondary {
      width: 100px;
      padding: 6px 20px;
    }
  }
}

#page-head {
  padding: 1.8em 0;
  width: 100%;
  margin: auto;
  z-index: 100;
  h1 {
    float: left;
    margin: 0;
    .driverside {
      background: center / contain no-repeat
        url(image_path('driverside-logo.svg'));
      background-size: 80%;
      display: block;
      height: 50px;
      text-indent: -9999px;
      width: 300px;
    }
    .motologic {
      background: center / contain no-repeat
        url(image_path('motologic-logo.svg'));
      background-size: 100%;
      display: block;
      height: 50px;
      text-indent: -9999px;
      width: 300px;
    }
  }

  nav {
    float: right;
    ul {
      @extend %clearfix;
      min-width: 180px;
    }
    li {
      display: inline;
      float: right;
    }
    li:first-child {
      margin-left: 0.9em;
    }
    a {
      border-radius: 1px;
      display: block;
      @extend .button;
      line-height: 27px;
    }
  }
  .hamburger-menu-icon {
    display: none;
    cursor: pointer;
  }
}

.hero-image {
  background-image: url(image_path('hero-image.jpg'));
  background-color: rgba(0, 0, 0, 0.5);
  background-blend-mode: multiply;
}

.ds-nav-spacer {
  height: 70px;
}

.hero-image,
.ds-hero-image {
  background-repeat: no-repeat;
  background-size: cover;
  color: $white;
  max-height: 900px;
  padding: $padding-sides;
  @include for-tablet-landscape-up {
    * {
      max-width: 55%;
    }
  }
  * {
    margin-top: 3em;
    max-width: 31rem;
    &:first-child {
      margin-top: 0;
    }
  }
  h2 {
    font-size: 3.2rem;
    font-style: italic;
    font-weight: 900;
    min-width: 9.6em;
  }
  p {
    font-size: $medium-header-font-size;
    font-family: 'Open Sans', sans-serif;
  }
}

.ds-hero-image {
  background-image: url(image_path('DS_Hero_small.jpg'));
  h2 {
    font-style: normal;
  }
  p {
    margin-top: 2rem;
  }
}

.ds-hero-mobile {
  display: none;
  width: 100%;
}

.ds-callout-container {
  background-color: $primary-color;
  text-align: center;
  color: white;
  padding: 2rem 4rem;
  h2 {
    font-size: 1.75rem;
    font-weight: 700;
    margin-bottom: 2rem;
  }
  h3 {
    color: $primary-color;
    font-size: 1.25rem;
    font-weight: 700;
    margin-bottom: 15px;
  }
  p {
    color: $secondary-color;
  }
}

.ds-callouts,
.ds-features {
  display: flex;
  gap: 2%;
}

.ds-icon img {
  width: 50%;
  position: relative;
  top: 4px;
}

.ds-callout-content {
  width: 25%;
  display: flex;
  flex-direction: column;
}

.ds-callout-textbox {
  padding: 25px 15px;
  flex-grow: 1;
}

.ds-features-container {
  background-color: $secondary-color-light;
  text-align: center;
  padding: 2rem 4rem;
  h2 {
    color: $secondary-color;
    font-size: 1.75rem;
    font-weight: 700;
    margin-bottom: 2rem;
  }
}

.ds-features {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  gap: 2%;
}

.ds-features-carousel {
  display: none;
}

.slider {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.right-arrow {
  position: absolute;
  top: 20%;
  right: 0;
  font-size: 3rem;
  color: $secondary-color;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}

.left-arrow {
  position: absolute;
  top: 20%;
  left: 0;
  font-size: 3rem;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}

.slider-arrow {
  background-color: transparent;
  background-image: none;
  border: none;
  padding: 0.5rem;
  height: 30%;
  max-height: initial;
  box-shadow: none;
  border: none;
  padding: 0.5rem;
  height: 30%;
  max-height: initial;
}

.slide {
  opacity: 0;
  transition-duration: 1s ease;
}

.slide.active {
  opacity: 1;
  transition-duration: 1s;
  transform: scale(1);
  display: flex;
  flex-direction: column;
  img {
    object-fit: contain;
    width: 100%;
  }
}

.ds-feature-content {
  flex-basis: 49%;
  display: flex;
  margin-bottom: 1rem;
}

.ds-feature-image {
  flex: 0 0 50%;
  display: flex;
  img {
    object-fit: cover;
    width: 100%;
  }
}

.ds-feature-text {
  padding: 1.25rem;
  background-color: white;
  text-align: left;
  h3 {
    color: $primary-color;
    font-weight: 700;
    padding: 0 0 10px 0;
  }
  p {
    color: $secondary-color;
  }
}

.ds-feature-carousel-text {
  padding: 1.25rem;
  background-color: white;
  text-align: left;
  height: 140px;
  h3 {
    color: $primary-color;
    font-weight: 700;
    padding: 0 0 10px 0;
  }
  p {
    color: $secondary-color;
  }
}

.ds-trial-container {
  background-color: $secondary-color;
  color: white;
  padding: 2rem 4rem;
}

.ds-trial {
  display: flex;
  flex-direction: row;
  gap: 2%;
}

.ds-trial-content {
  width: 50%;
  img {
    width: 100%;
  }
  h2 {
    font-size: 2rem;
    color: white;
    font-weight: 700;
    padding: 0 0 10px 0;
    margin-bottom: 1rem;
  }
  p {
    color: white;
    padding: 0 0 10px 0;
    margin-bottom: 1rem;
  }
}

.ds-trial-image {
  display: flex;
  img {
    object-fit: cover;
    width: 100%;
    max-height: 230px;
  }
}

.ds-mobile-trial-image {
  display: none;
}

.ds-subscribe-container {
  background-color: $secondary-color-light;
  padding: 2rem 4rem 6rem 4rem;
  h2 {
    color: $primary-color;
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 1rem;
    text-align: center;
  }
  p {
    text-align: center;
    margin-bottom: 2rem;
  }
}

.ds-subscribe {
  display: flex;
  flex-direction: row;
  gap: 2%;
}

.ds-subscribe-content {
  background-color: white;
  padding: 1rem;
  display: flex;
  width: 50%;
  h3 {
    color: $primary-color;
    font-size: 1rem;
    font-weight: 700;
    margin-bottom: 1rem;
  }
}

.ds-subscribe-text {
  width: 75%;
  p {
    text-align: left;
    margin-bottom: 1rem;
  }
  span {
    font-weight: 600;
  }
}

.ds-subscribe-price {
  text-align: right;
  width: 25%;
  align-self: flex-end;
  h3 {
    font-size: 2rem;
    color: $secondary-color;
    font-weight: 700;
    margin: 0;
  }
}

.ds-subscribe-price-mobile {
  display: none;
}

.white-background {
  background-color: white;
}

@include for-tablet-portrait-up() {
  .hero-image {
    padding: 15.5% $padding-sides 7.5% $padding-sides;
  }
}
.spacer {
  background-color: $primary-color;
  background-image: url(image_path('organic-tiles.png'));
  background-repeat: repeat;
  background-blend-mode: multiply;
  max-height: 510px;
  text-align: center;

  .computer-image {
    width: 1000px;
    position: relative;
    display: block;
    margin: auto;
    top: -25px;
  }
  .video-screen {
    width: 760px;
    margin: auto;
    display: block;
    position: relative;
    top: -660px;
    border: 1px solid lightgrey;
  }
}

.callouts {
  background-color: $light-gray;
  padding: 6em $padding-sides 3em $padding-sides;
}

@include for-tablet-portrait-up {
  .quicksell-group,
  .quicksell-group-two {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }
  .quicksell-group {
    padding-bottom: 60px;
  }
  .quicksell-group-two {
    padding-top: 60px;
    padding-bottom: 110px;
  }
  .quicksell-icon {
    margin-top: 0;
  }
}
.quicksell-item {
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  min-width: 16rem;
  &.width-25 {
    flex-basis: 25%;
  }
  &.width-30 {
    flex-basis: 35%;
  }
  &.width-50 {
    flex-basis: 61%;
  }
  a {
    border-radius: 1px;
    display: block;
    @extend .button;
    line-height: 27px;
  }
  * {
    &:first-child {
      margin-top: 0;
    }
    margin-top: 1.5em;
  }
  img {
    max-width: 100%;
  }
  @include for-phone-only() {
    img {
      max-width: 65%;
    }
  }
  p {
    font-family: 'Open Sans', sans-serif;
    font-size: $p-font-size;
    max-width: 90%;
  }
  &.special-case {
    margin-bottom: 2em;
    p {
      max-width: 15.2em !important;
    }
  }
  &.frontpage-car {
    img {
      position: relative;
      transform: translateY(-4%);
    }
  }
}
.quicksell-icon {
  max-height: 30%;
}
h3.medium-header {
  color: $primary-color;
  font-style: italic;
  font-weight: bold;
  font-size: $medium-header-font-size;
  font-family: 'Source Sans Pro', sans-serif;
  margin-top: 0;
}
hr {
  border: solid $primary-color;
  border-width: 0.1rem 0 0 0;
  margin: 6em auto 6em auto;
}
@media (max-width: 1120px) {
  .spacer {
    max-height: 370px;
    .computer-image {
      width: 750px;
      top: -30px;
    }
    .video-screen {
      width: 570px;
      top: -506px;
    }
  }
}

@media (max-width: 950px) {
  .ds-callouts {
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .ds-callout-content {
    width: 45%;
    margin: 1rem 0;
  }
  .ds-features {
    display: none;
  }

  .ds-features-carousel {
    display: block;
  }
  .ds-hero-image {
    padding: 4rem;
  }
}

@media (max-width: 750px) {
  .hero-image {
    background-position: right center;
  }
  #page-head {
    padding: 1.5em 2em;
  }
  .quicksell-item {
    margin-top: 5em;
    &:first-of-type {
      margin-top: 0;
    }
  }
  .quicksell-item.width-25 {
    flex-basis: 100%;
  }
  .spacer {
    max-height: 235px;
    .computer-image {
      width: 500px;
      top: -30px;
    }
    .video-screen {
      width: 380px;
      top: -348px;
    }
  }

  .ds-subscribe {
    flex-direction: column;
  }

  .ds-subscribe-content {
    width: 100%;
    margin-bottom: 1rem;
  }
}
@include for-phone-only() {
  .hero-image {
    h2 {
      font-size: 2.5rem;
      min-width: 0;
      text-align: center;
    }
    p {
      font-size: 1.25rem;
      text-align: center;
    }
    .button {
      margin: 3em auto 0 auto;
      display: block;
    }
  }
  .spacer {
    max-height: 235px;
    .computer-image {
      display: none;
    }
    .video-screen {
      display: none;
    }
  }
  hr {
    margin: 4.5em auto;
  }
  .quicksell-item {
    &:first-child {
      margin-top: 0;
    }
    margin-top: 3em;

    p {
      max-width: 90% !important;
    }
  }
  .quicksell-group {
    padding-bottom: 3em;
  }
  .quicksell-group-two {
    padding-top: 3em;
  }
  .callouts {
    padding: 2em;
  }
  .frontpage-header-container {
    position: static;
    height: 130px;
  }
  .header-cell {
    width: 100%;
  }
  #page-head nav {
    float: none;
    width: 100%;
  }
  #page-head nav li:first-child {
    margin-left: 0;
  }
  #page-head nav ul {
    width: 100%;
    li {
      width: 50%;
      display: inline-block;
      margin: 10px 0 0 0;
      .button.button-primary {
        width: 100%;
        height: 30px;
        padding: 2px 0;
        font-size: 14px;
      }
      .button.button-secondary {
        width: 100%;
        height: 30px;
        padding: 2px 20px;
        font-size: 14px;
      }
    }
  }
  #page-head h1 {
    float: none;
  }
  #page-head div div div h1 a {
    margin: auto;
    width: 220px;
  }
  .hero-image {
    padding: 5.5em 4em 4.5em 4em;
  }
  .ds-callout-content {
    width: 100%;
    margin: 1rem 0;
  }
  .ds-icon img {
    width: 40%;
  }

  .ds-trial-image {
    display: none;
  }

  .ds-mobile-trial-image {
    display: block;
    margin-bottom: 1.25rem;
  }
  .ds-trial-content {
    width: 100%;
    text-align: center;
  }
  // .ds-nav-spacer {
  //   display: none;
  // }
  .ds-hero-image {
    background-image: none;
    background-color: $secondary-color;
    text-align: center;
    padding: 6rem 2.5rem 2.5rem 2.5rem;
    h2 {
      font-size: 2rem;
      min-width: 0;
    }
    p {
      margin-top: 2rem;
      font-size: 1.25rem;
    }
  }

  .ds-hero-mobile {
    display: block;
  }

  .ds-subscribe {
    flex-direction: column;
  }

  .ds-subscribe-content {
    margin-bottom: 1rem;
  }

  .ds-subscribe-text {
    width: 100%;
    text-align: center;
    p {
      text-align: center;
    }
  }

  .ds-subscribe-price {
    display: none;
  }

  .ds-subscribe-price-mobile {
    display: block;
    text-align: center;
    font-size: 2rem;
    color: $secondary-color;
    font-weight: 700;
    margin-bottom: 1rem;
  }

  .ds-feature-carousel-text {
    height: 160px;
  }
}

@media (max-width: 500px) {
  .ds-feature-carousel-text {
    height: 180px;
  }
}

@media (max-width: 420px) {
  .ds-feature-carousel-text {
    height: 200px;
  }
  .right-arrow,
  .left-arrow {
    top: 10%;
  }
}

@media (max-width: 393px) {
  .ds-feature-carousel-text {
    height: 220px;
  }
}

.footer {
  background-color: #fff;
  height: 3em;
  color: $white;
  bottom: 75px;
  width: 100%;
  .links {
    float: right;
    padding-right: 2rem;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    * {
      display: inline;
      color: $secondary-color;
      background-color: $white;
      padding-left: 3em;
      font-size: 0.85rem;
    }
    a {
      text-decoration-line: underline;
    }
  }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  #body-wrapper {
    background-image: -ms-linear-gradient(left, white, $secondary-color, white);
  }
  .button.button-secondary {
    background-color: #d2cccc;
  }
  .hero-image {
    background-image: url(image_path('hero-image-IE.jpg'));
    .button {
      width: 170px;
    }
  }
  .spacer {
    background-image: url(image_path('organic-tiles-IE.png'));
  }
  .quicksell-icon {
    max-height: none;
  }
  @include for-tablet-portrait-up {
    .quicksell-group,
    .quicksell-group-two {
      justify-content: space-between;
    }
    .quicksell-group {
      padding-bottom: 100px;
    }
  }
  .quicksell-item {
    &.frontpage-car {
      img {
        max-height: 190%;
      }
    }
  }
}

@supports (-ms-ime-align: auto) {
  #body-wrapper {
    background-image: -ms-linear-gradient(left, white, $secondary-color, white);
  }
  .button.button-secondary {
    background-color: #d2cccc;
  }
  .hero-image {
    background-image: url(image_path('hero-image-IE.jpg'));
    .button {
      width: 170px;
    }
  }
  .spacer {
    background-image: url(image_path('organic-tiles-IE.png'));
  }
  .quicksell-icon {
    max-height: none;
  }
  @include for-tablet-portrait-up {
    .quicksell-group,
    .quicksell-group-two {
      justify-content: space-between;
    }
    .quicksell-group {
      padding-bottom: 100px;
    }
  }
  .quicksell-item {
    &.frontpage-car {
      img {
        max-height: 190%;
      }
    }
  }
}

@media (max-width: 480px) {
  .footer {
    .links {
      display: inline-flex;
      padding: 2px;
      * {
        padding-left: 0.5em;
      }
    }
    p {
      padding: 2px;
    }
  }
}
